/**
 * @todo - everything in this file should probably be changed, since
 * we looking for a swedish key
 */

import { type IProduct } from '~/api-types';

export default function useProduct() {
  const getStrength = (product: IProduct): number => {
    const strength = product.facts.find((i) => {
      return i.code === 'StrengthKey';
    });
    switch (strength?.value?.replace(/\s*$/,'')) {
      case 'Low':
        return 1;
      case 'Normal':
        return 2;
      case 'Strong':
        return 3;
      case 'Extra Strong':
        return 4;
      case 'Super Strong':
        return 5;
      default:
        return 0;
    }
  };
  const getFormat = (product: IProduct): string => {
    const format = product.facts.find((i) => {
      return i.name === 'Format';
    });
    return format?.value || '';
  };

  const getFlavor = (product: IProduct): string => {
    const flavor = product.facts.find((i) => {
      return i.name === 'Smak';
    });
    return flavor?.value || '';
  };

  return {
    getStrength,
    getFormat,
    getFlavor,
  };
}
